.ant-input-status-error {
  background-color: var(--error-bg);
  color: var(--error-text);
}

.ant-input-status-error::placeholder {
  color: var(--error-text);
}

.ant-form-item .ant-form-item-explain-error {
  color: var(--error-text);
}
