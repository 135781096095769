.header__left-side {
  gap: 40px;
}

.header__left-side-mobile {
  gap: 24px;
}

.header__arrow-container {
  gap: 11px;
}

.header__button-container {
  gap: 40px;
}

.ant-drawer-mask {
  margin-top: 104px;
}

.burger {
  width: 30px;
  height: 18px;
  position: relative;
  cursor: pointer;
}
.burger::before {
  position: absolute;
  content: '';
  width: 30px;
  height: 2px;
  background-color: #3987cf;
  top: 0;
  right: 0;
}
.burger::after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background-color: #3987cf;
  bottom: 0;
  right: 0;
}
.burger span {
  position: absolute;
  content: '';
  width: 25px;
  height: 2px;
  background-color: #3987cf;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
