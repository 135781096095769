.moveable-control-box .moveable-line .moveable-direction {
  border: 2px dashed var(--blue);
  border-left: 44px solid var((--blue));
  border-right: 44px solid var(--blue);
  opacity: 0.66;
}

.moveable-control-box .moveable-control {
  border: none !important;
  background: var(--blue) !important;
  width: 12px !important;
  opacity: 0.66;
  width: 24px !important;
  height: 24px !important;
  left: -6px !important;
}

.moveable-control-box .moveable-rotation-control {
  background-image: url('../../assets/images/Union.png') !important;
  background-size: 75px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;

  top: -100px !important;
  left: -40px !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 5px !important;
}

.moveable-rotation:nth-child(6) .moveable-rotation-control {
  background-color: transparent !important;
  left: -115px !important;
  top: -20px !important;
  transform: rotate(95deg) !important;
  background-image: url('../../assets/images/rotare-corner.png') !important;
}

.moveable-rotation:nth-child(7) .moveable-rotation-control {
  background-color: transparent !important;
  left: 20px !important;
  top: -20px !important;
  transform: rotate(-120deg) !important;
  background-image: url('../../assets/images/rotare-corner.png') !important;
}

.moveable-rotation:nth-child(8) .moveable-rotation-control {
  background-color: transparent !important;
  left: 20px !important;
  top: -20px !important;
  transform: rotate(-120deg) !important;
  background-image: url('../../assets/images/rotare-corner.png') !important;
}

.moveable-rotation:nth-child(9) .moveable-rotation-control {
  background-color: transparent !important;
  left: -90px !important;
  top: -20px !important;
  transform: rotate(95deg) !important;
  background-image: url('../../assets/images/rotare-corner.png') !important;
}

.moveable-control-box .moveable-rotation-line {
  height: 120px !important;
  top: -70px !important;
  display: none !important;
}

.moveable-control {
  border-radius: 0 !important;
}

.moveable-line {
  height: 3px !important;
}
