@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.woff2'),
    url('./assets/fonts/Inter-Regular.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-bold';
  src: url('./assets/fonts/Inter-ExtraBold.woff2'),
    url('./assets/fonts/Inter-ExtraBold.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-reg';
  src: url('./assets/fonts/Roboto-Regular.woff2'),
    url('./assets/fonts/Roboto-Regular.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.woff2'),
    url('./assets/fonts/Roboto-Medium.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/Helvetica-Regular.woff2'),
    url('./assets/fonts/Helvetica-Regular.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica-bold';
  src: url('./assets/fonts/Helvetica-Bold.woff2'),
    url('./assets/fonts/Helvetica-Bold.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-bold';
  src: url('./assets/fonts/Montserrat-Bold.woff2'),
    url('./assets/fonts/Montserrat-Bold.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-extra-bold';
  src: url('./assets/fonts/Montserrat-ExtraBold.woff2'),
    url('./assets/fonts/Montserrat-ExtraBold.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  /* letter-spacing: 0px !important; */
}
