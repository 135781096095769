:root {
  /* если чего-то не хватает из theme.ts - добавить */
  --blue-50: rgba(57, 135, 207, 0.05);
  --blue-100: rgba(57, 135, 207, 0.1);
  --blue-200: rgba(57, 135, 207, 0.25);
  --blue-500: rgba(57, 135, 207, 0.5);

  --blue: #3987cf;
  --blue-600: 1B69B1;
  --blue-700: rgba(57, 135, 207, 0.7);
  --grey-secondary: #e2e2e2;
  --grey: #888888;

  --black: #1f1f1f;
  --black-acc: #1d1d1d;
  --green: #4caf50;
  --placeholder-grey: #696a80;

  --screen-background: linear-gradient(180deg, #e8f4ff 0%, #ffffff 100%);

  --orange: #ff4500;

  --white: #fff;

  --error-bg: #f8d7da;
  --error-text: #721c24;
  --white: #fff;
  --lg-size: 18px;
  --lg-height: 20px;
  --xs-size: 12px;
  --xs-height: 14px;
  --sm-size: 14px;
  --sm-height: 16px;
  --base-size: 16px;
  --base-height: 18px;
}

/* Не хочет меняться в конфигурации antd поэтому тут */
.ant-typography-secondary {
  color: var(--blue-200) !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.row-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.normal-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}

.ant-select-item-option-selected {
  background-color: var(--blue-100) !important;
}
