.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 4px;
  height: 3px;
}

/* for mozila */
* {

  scrollbar-color: var(--blue);
}

::-webkit-scrollbar-track {
  background-color: var(--blue-200); /* Задний цвет скроллбара */
}
::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 100px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}

.ant-input {
  box-shadow: none !important;
}
/* disabled border for select all project  */
